import classNames from 'classnames';
import { ReactElement } from 'react';

// UI Kit
import BackgroundImage from 'ui-kit/background-image/background-image';
import TelemedicineIcon from 'ui-kit/icons/telemedicine-icon/telemedicine-icon';
import PageSection from 'ui-kit/page-section/page-section';

// Components
import WorkflowLayoutFormWrapper from 'components/workflow-layout-form-wrapper/workflow-layout-form-wrapper.component';

// HOC
import withSessionExpiration from 'hoc/withSessionExpiration';

import PageLayout from '../page/page.layout';
import './checkout-layout.style.scss';

interface CheckoutLayoutProps {
    children: ReactElement | JSX.Element;
    backgroundImage: any;
    nodeTitle: string;
    title: string;
    eyebrowText: string;
    className?: string;
}

const CheckoutLayout = ({
    children,
    backgroundImage,
    nodeTitle,
    title,
    eyebrowText,
    className
}: CheckoutLayoutProps) => {
    return (
        <PageLayout metaData={{ nodeTitle: nodeTitle }}>
            <BackgroundImage image={backgroundImage}>
                <PageSection className="page-layout">
                    <WorkflowLayoutFormWrapper
                        title={title}
                        eyebrowText={eyebrowText}
                        className={classNames('checkout-layout-form-wrapper', `${className}`)}
                        icon={<TelemedicineIcon />}
                        isIconContainer
                    >
                        {children}
                    </WorkflowLayoutFormWrapper>
                </PageSection>
            </BackgroundImage>
        </PageLayout>
    );
};

export default withSessionExpiration(CheckoutLayout);
