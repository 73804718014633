import React from 'react';

// Styles
import './checkout-section-header.style.scss';

export interface CheckoutSectionHeaderProps {
    label: string;
}

const CheckoutSectionHeader: React.FC<CheckoutSectionHeaderProps> = ({ label }) => {
    return (
        <div className="checkout-section-header">
            <div className="checkout-section-header-title">
                <div className="checkout-section-header-title-pill mr-3" />
                <div className="checkout-section-header-title-label">{label}</div>
            </div>
        </div>
    );
};

export default CheckoutSectionHeader;
