interface TelemedicineIconProps {
    color?: string;
    width?: string;
    height?: string;
}

const TelemedicineIcon = ({ color = 'blue', width = '24', height = '24' }: TelemedicineIconProps) => {
    const colors: Record<string, string> = {
        blue: '#1a497f',
        black: '#222A3F'
    };

    const selectedColor = colors[color] || color;

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" fill="none">
            <path
                d="M10.5 18L8.5 22M13.5 18L15.5 22M6.85718 22H17.1429M21.2308 3H2.76923C2.3444 3 2 3.34823 2 3.77778V16.2222C2 16.6517 2.3444 17 2.76923 17H21.2308C21.6555 17 22 16.6517 22 16.2222V3.77778C22 3.34823 21.6555 3 21.2308 3ZM10.9903 6C10.6471 6 10.3689 6.27819 10.3689 6.62135V8.36897H8.62135C8.27819 8.36897 8 8.64716 8 8.99032V11.0097C8 11.3529 8.27819 11.6311 8.62135 11.6311H10.3689V13.3786C10.3689 13.7218 10.6471 14 10.9903 14H13.0097C13.3529 14 13.6311 13.7218 13.6311 13.3786V11.6311H15.3786C15.7218 11.6311 16 11.3529 16 11.0097V8.99032C16 8.64716 15.7218 8.36897 15.3786 8.36897H13.6311V6.62135C13.6311 6.27819 13.3529 6 13.0097 6H10.9903Z"
                stroke={selectedColor}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default TelemedicineIcon;
