import { TFunction } from 'i18next';
import { Col, Container, Row } from 'react-bootstrap';
import sanitizeHtml from 'sanitize-html';

import Button from 'ui-kit/button/button';
import ToastBox from 'ui-kit/toast-box/toast-box';

import { getPhoneNumber } from 'util/globalVariables';
import { convertToTitleCase } from 'util/string';

import './membership-modals.styles.scss';

export interface ErrorModalContentProps {
    variation:
        | 'sendInvitation'
        | 'addMember'
        | 'sendInvitationAssociated'
        | 'generateScheduleLink'
        | 'addNewMember'
        | 'genericError'
        | 'paymentError';
    memberName: string;
    onClose: () => void;
    t: any;
}

interface TranslationsErrorType {
    mainText: string;
    subText?: string;
    blurbText?: string;
}

interface MembershipAddMemberAlreadyExistErrorModalProps {
    name: string;
    onCancelClick?: () => void;
    onContinueClick?: () => void;
    translation: TFunction<'translation'>;
}

export const MembershipSettingsErrorModal = ({ variation, memberName, onClose, t }: ErrorModalContentProps) => {
    const translations: TranslationsErrorType = {
        mainText: '',
        subText: undefined
    };

    switch (variation) {
        case 'addMember':
            translations.mainText = 'components.membershipModals.addMemberErrorGeneric.title';
            break;
        case 'addNewMember':
            translations.mainText = 'components.membershipModals.addNewMemberErrorGeneric.title';
            break;
        case 'sendInvitation':
            translations.mainText = 'components.membershipModals.sendInvitationErrorGeneric.title';
            break;
        case 'sendInvitationAssociated':
            translations.mainText = 'components.membershipModals.sendInvitationErrorAssociated.title';
            break;
        case 'generateScheduleLink':
            translations.mainText = 'components.membershipModals.generateScheduleLinkErrorGeneric.title';
            break;
        case 'genericError':
            translations.mainText = 'components.membershipModals.genericError.title';
            break;
        case 'paymentError':
            translations.mainText = 'components.membershipModals.paymentError.title';
            break;
        default:
            translations.mainText = '';
            break;
    }

    return (
        <div className="membership-error-modal">
            <div className="membership-error-modal--content">
                <div className="membership-error-modal--body">
                    <h4
                        dangerouslySetInnerHTML={{
                            __html: sanitizeHtml(t(translations.mainText, { memberName }), {
                                allowedTags: ['br']
                            })
                        }}
                    />
                    {translations.subText && <p>{t(translations.subText)}</p>}
                </div>

                <div className="membership-error-modal--foot-notes">
                    <ToastBox variant="descriptive">
                        <div
                            dangerouslySetInnerHTML={{
                                __html: sanitizeHtml(
                                    t('components.membershipModals.footNote', {
                                        phoneNumber: getPhoneNumber({ isEnd: true })
                                    }),
                                    {
                                        allowedTags: ['strong']
                                    }
                                )
                            }}
                        />
                    </ToastBox>
                </div>
                <Button
                    label={t('components.membershipModals.buttons.gotIt')}
                    type="button"
                    variant="primary"
                    onClick={onClose}
                />
            </div>
        </div>
    );
};

interface SuccessModalContentProps {
    variation: 'invitation' | 'addMember';
    memberName: string;
    onClose: () => void;
    t: any;
}

export const MembershipSettingsSuccessModalContent = ({
    variation,
    memberName,
    onClose,
    t
}: SuccessModalContentProps) => {
    const translationText = 'pages.profile.membership.manageGroupSection.modalSuccessAddMember.messageName';
    const translationSubText = 'pages.profile.membership.manageGroupSection.modalSuccessAddMember.message';

    return (
        <div className="membership-manage-group-success-modal">
            <div>
                <p>{t(translationText, { memberName })}</p>
                <p>{t(translationSubText)}</p>
            </div>
            <Button
                label={t('pages.profile.membership.manageGroupSection.modalSuccessAddMember.ctaContinue')}
                type="button"
                variant="primary"
                onClick={onClose}
            />
        </div>
    );
};

export const MembershipSettingsInviteSuccessModalContent = ({
    memberName,
    onClose,
    t
}: Partial<SuccessModalContentProps>) => {
    const translationText = 'pages.profile.membership.manageGroupSection.modalSuccessInvite.messageName';

    return (
        <div className="membership-manage-group-success-modal">
            <div>
                <p>{t(translationText, { memberName })}</p>
            </div>
            <Button
                label={t('pages.profile.membership.manageGroupSection.modalSuccessInvite.ctaContinue')}
                type="button"
                variant="primary"
                onClick={onClose}
            />
        </div>
    );
};

export const MembershipAddMemberAlreadyExistErrorModalContent = ({
    name,
    onContinueClick,
    translation
}: MembershipAddMemberAlreadyExistErrorModalProps) => {
    return (
        <Container className="family-account-modals" fluid>
            <Row>
                <Col className="family-account-modals-content">
                    <h4>
                        {translation('modals.membership.addMemberAlreadyMemberErrorText', {
                            name: convertToTitleCase(name)
                        })}
                    </h4>
                </Col>
            </Row>
            <div className="foot-notes">
                <ToastBox variant="descriptive">
                    <div
                        dangerouslySetInnerHTML={{
                            __html: sanitizeHtml(
                                translation('modals.easyRefillFailure.footNotes', {
                                    phoneNumber: getPhoneNumber({ isEnd: true })
                                })
                            )
                        }}
                    />
                </ToastBox>
            </div>
            <Row className="family-account-modals-ctas">
                <Col>
                    <Button
                        label={translation('modals.familyAccount.gotIt')}
                        variant="primary"
                        onClick={onContinueClick}
                        type="button"
                    />
                </Col>
            </Row>
        </Container>
    );
};
